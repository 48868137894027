import { useInView } from 'react-intersection-observer';
import { classNames } from '../utils/classNames';
import ResponseWrapper from './template-cards/ResponseWrapper';
import { useEffect } from 'react';
import { useAppContext } from '../context/AppContextProvider';

const SectionContainer = ({ page, pageIndex, isFirstPage, isFetchingNextPage }) => {
	const { setNavigationPath, setAvailableNavigationList } = useAppContext();

	const threshold = page.sectionId === 'home' ? 0.5 : 0.2;

	const { ref, inView } = useInView({
		threshold: threshold,
		triggerOnce: true,
	});

	const handleSectionVisibility = (sectionId, inView) => {
		if (inView) {
			setNavigationPath(sectionId);
			setAvailableNavigationList((prevList) => {
				if (!prevList.includes(sectionId)) {
					return [...prevList, sectionId];
				}

				return prevList;
			});
		}
	};

	useEffect(() => {
		if (inView) handleSectionVisibility(page.sectionId, inView);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inView, page.sectionId]);

	return (
		<section key={pageIndex} id={page.sectionId} ref={ref} className='relative'>
			<div ref={page.sectionRef}>
				{!isFirstPage && <div className='h-20 lg:h-4 w-full' />}

				<div className={classNames('relative flex justify-center w-full lg:pb-12')}>
					{page.type === 'section' && <ResponseWrapper page={page} sectionLoading={isFetchingNextPage} />}

					{page.type === 'user-message' && (
						<div
							className={classNames(
								'max-w-[820px] w-full',
								'lg:max-w-[clamp(550px,55%,820px)]',
								'xl:max-w-[clamp(600px,60%,820px)]',
								'xxl:max-w-[clamp(600px,70%,820px)]'
							)}
						>
							<div className='max-w-[600px] ml-auto text-right'>
								<div className='inline-block max-w-full py-[10px] px-8 rounded-[30px] bg-accentGradientLight'>
									<span className='font-semibold text-lg leading-[25px] break-words'>{page.items[0].text}</span>
								</div>
							</div>
						</div>
					)}

					{page.type === 'form-response' && <ResponseWrapper page={page} />}

					{page.type === 'questions' && <ResponseWrapper page={page} />}
				</div>
			</div>
		</section>
	);
};

export default SectionContainer;
