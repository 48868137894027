import { useEffect } from 'react';
import { classNames } from '../../utils/classNames';
import { NAVIGATION } from '../../constants/navigation';
import { useAppContext } from '../../context/AppContextProvider';

import MobileLogo from '../MobileLogo';
import Navigation from './Navigation';
import { ReactComponent as CloseIcon } from '../../assets/navigation/close.svg';
import menuIcon from '../../assets/navigation/home.svg';

const MobileNavigation = () => {
	const {
		mobileNavigationOpen,
		setMobileNavigationOpen,
		mobileNavigationFixed,
		setMobileNavigationFixed,
		scrollStartRef,
		navigationPath,
		isRequestPopupOpen,
		setHomeSectionVisible,
	} = useAppContext();

	const currentSection = NAVIGATION.find(({ value }) => value === navigationPath);

	useEffect(() => {
		const handleScroll = () => {
			if (window.innerWidth >= 1024) return;

			if (scrollStartRef.current) {
				const startRefTop = scrollStartRef.current.getBoundingClientRect().top;

				if (startRefTop <= 0 && !mobileNavigationFixed) {
					setMobileNavigationFixed(true);
				} else if (startRefTop > 0 && mobileNavigationFixed) {
					setMobileNavigationFixed(false);
				}
			}
		};

		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, [mobileNavigationFixed, setMobileNavigationFixed, scrollStartRef, setHomeSectionVisible]);

	return (
		<div className='w-full mx-auto relative'>
			{mobileNavigationOpen && (
				<div
					className={classNames(
						'fixed inset-0 bg-black',
						'transition-opacity duration-500 ease-in-out z-[41]',
						mobileNavigationOpen ? ' opacity-[56%] visible' : 'opacity-0 invisible'
					)}
				/>
			)}

			<div
				className={classNames(
					mobileNavigationFixed ? 'fixed top-4 sm:left-1/2 sm:-translate-x-1/2 w-[calc(100%-16px)]' : 'relative m-auto',
					'max-w-[820px] lg:hidden',
					isRequestPopupOpen ? 'z-[40]' : 'z-[41]'
				)}
			>
				<div
					className={classNames(
						'w-full flex flex-col justify-start',
						'mx-auto px-2 xs:px-4 py-2 mb-4 rounded-xl z-[41]',
						'shadow-navigationShadow bg-backgroundColor-secondary'
					)}
				>
					<div className='w-full flex items-center justify-between h-[31px]'>
						<MobileLogo className='pl-2 xs:pl-0' />

						<div className='flex items-center gap-1'>
							<div className='flex text-xs'>
								<span
									className={classNames(
										!mobileNavigationOpen && currentSection?.label
											? 'text-[#00000080] pr-2 border-r border-[#00000080]'
											: ''
									)}
								>
									Menu
								</span>

								{!mobileNavigationOpen && currentSection?.label && (
									<span className='pl-2'>{currentSection?.label}</span>
								)}
							</div>

							<button
								onClick={() => setMobileNavigationOpen(!mobileNavigationOpen)}
								aria-label={mobileNavigationOpen ? 'Close navigation menu' : 'Open navigation menu'}
							>
								{mobileNavigationOpen ? (
									<CloseIcon className='fill-none' />
								) : currentSection?.IconComponent ? (
									<currentSection.IconComponent className='h-[31px] w-[31px]' />
								) : (
									<img src={menuIcon} alt='menu' className='h-[31px] w-[31px]' />
								)}
							</button>
						</div>
					</div>

					<div
						className={classNames(
							'transition-all ease-soft-spring duration-500 overflow-hidden',
							mobileNavigationOpen ? 'max-h-[500px] opacity-100' : 'max-h-0 opacity-0'
						)}
					>
						<div className='max-w-[315px] w-full m-auto py-14'>
							<Navigation mode='mobile' className='w-full grid grid-cols-4 gap-x-[10.25px] gap-y-10' />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MobileNavigation;
